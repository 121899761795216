import type { Sections } from './types'

const defaultProps = {
  card: {
    layout: 'magazine',
    description: true,
  },
  template: [
    {
      index: 1,
      data: {
        description: true,
      },
      type: 'notice',
    },
  ],
}

const PAGES = {
  HOME: {
    path: '/category/:category',
    component: 'home',
    title: 'Inicio',
  },
  NEWS: {
    path: '/news/:id',
    component: 'notice',
  },
}

const SECTIONS: Sections = {
  PORTADA: {
    ad: 'principal',
    key: 'portada',
    label: 'Portada',
    path: '/category/portada',
  },
}

export default {
  name: 'depor',
  url: ['pwa.depor.com', 'pwa.dev.depor.com'],
  main: '/category/portada',
  marketing: {
    url: 'https://depor.com/',
    domain: 'depor.com',
    brand: 'Depor',
    firstColor: '#007c31',
    secondaryColor: '#f25a23',
    font:
      'https://fonts.googleapis.com/css2?family=Merriweather:wght@900&display=swap',
  },
  categories: [SECTIONS.PORTADA],
  navigation: {
    topTabNavigator: [],
  },
  navigationTop: [
    {
      key: 'logotipo',
      image: '/brands/depor/logo.svg',
      type: 'logotipo',
    },
  ],
  ads: {
    home: [],
  },
  pages: [
    { ...PAGES.HOME },
    { ...PAGES.NEWS },
  ],
  jwplayers: {
    gec: { player: 'nM3HvVdY', playerAds: 'Hm6R42Pa' },
  },
  defaultProps,
}
