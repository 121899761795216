import type { Sections } from './types'

const defaultProps = {
  card: {
    layout: 'magazine',
    header: false,
  },
  template: [{ type: 'notice' }],
}

const PAGES = {
  HOME: {
    path: '/category/:category',
    component: 'home',
  },
  NEWS: {
    path: '/news/:id',
    component: 'notice',
  },
}

const SECTIONS: Sections = {
  PORTADA: {
    ad: 'principal',
    key: 'portada',
    label: 'Portada',
    path: '/category/portada',
  },
}

export default {
  name: 'trome',
  url: ['pwa.trome.pe', 'pwa.dev.trome.pe'],
  main: '/category/portada',
  marketing: {
    url: 'https://trome.com/',
    domain: 'trome.com',
    brand: 'Trome',
    firstColor: '#FF8400',
    secondaryColor: '#EF5B25',
    font:
      'https://fonts.googleapis.com/css2?family=Encode+Sans+Condensed:wght@400;500;600;900&display=swap',
    secondaryFont:
      'https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;800;900&display=swap',
  },
  categories: [SECTIONS.PORTADA],
  navigationTop: [
    {
      key: 'logotipo',
      image: '/brands/trome/logo.svg?v=2',
      type: 'logotipo',
    },
  ],
  navigation: {
    topTabNavigator: [],
  },
  components: {},
  ads: {
    home: [],
  },
  pages: [
    { ...PAGES.HOME },
    { ...PAGES.NEWS },
  ],
  jwplayers: {
    gec: { player: 'mw2yaIG8', playerAds: 'TxIvz082' },
  },
  defaultProps,
}
