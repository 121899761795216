import type { Sections } from './types'

const defaultProps = {
  card: {
    layout: 'magazine',
    description: true,
    author: true,
  },
  template: [
    {
      index: 1,
      data: {
        author: true,
        description: true,
      },
      type: 'notice',
    },
  ],
}

const PAGES = {
  HOME: {
    path: '/category/:category',
    component: 'home',
    title: 'Inicio',
  },
  NEWS: {
    path: '/news/:id',
    component: 'notice',
  },
}

const SECTIONS: Sections = {
  PORTADA: {
    ad: 'principal',
    key: 'portada',
    label: 'Portada',
    path: '/category/portada',
  },
}

export default {
  name: 'elcomercio',
  url: ['pwa.elcomercio.pe', 'pwa.dev.elcomercio.pe'],
  main: '/category/portada',
  marketing: {
    url: 'https://elcomercio.pe/',
    domain: 'elcomercio.pe',
    brand: 'El Comercio',
    firstColor: '#FFCB05',
    secondaryColor: '#FFCB05',
  },
  categories: [SECTIONS.PORTADA],
  internalPages: {
    terminos:
      'https://ecoid.pe/terminos_y_condiciones/a94a8fe5ccb19ba61c4c0873d391e987982fbbd3',
    politicas:
      'https://ecoid.pe/politica_privacidad/a94a8fe5ccb19ba61c4c0873d391e987982fbbd3',
  },
  navigation: {
    topTabNavigator: [],
  },
  navigationTop: [
    {
      key: 'logotipo',
      image: '/brands/elcomercio/logo.svg',
      type: 'logotipo',
    },
  ],
  ads: {
    home: [],
  },
  pages: [
    { ...PAGES.HOME },
    { ...PAGES.NEWS },
  ],
  jwplayers: {
    gec: { player: 'QesGNLbA', playerAds: 'oxSCGgVV' },
    elcomercio: { player: 'VWAez0JC', playerAds: 'diFqwoLd' },
  },
  suscription: {
    signwallCount: 4,
    paywallCount: 9,
  },
  defaultProps,
}
