import * as Sentry from '@sentry/browser'
import React from 'react'

type Props = {
  fallback: React.ReactNode
}

class ErrorBoundary extends React.Component<Props> {
  state: { hasError: boolean }

  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureMessage('ErrorBoundary', Sentry.Severity.Info)
    Sentry.captureException(error, errorInfo)
    alert(`Error: ${error.message}\n${error.stack}`)
  }

  render() {
    if (this.state.hasError) return this.props.fallback
    return this.props.children
  }
}

export default ErrorBoundary
