import './tools/polyfill'

import classNames from 'classnames'
import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import Loadable from 'react-loadable'
import { connect } from 'react-redux'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import * as Sentry from '@sentry/browser'

import { LoadPage } from './components/ePlaceHolder'
import UserConfig from './config/web-config'
import ErrorBoundary from './containers/ErrorBoundary'
import { AppContext } from './context/app'
import scrollCategories from './pages/portrait/scroll'
import SectionPage from './pages/section'
import {
  clearFavorites,
  initStateFavorites,
  setFavoritesIds,
  setFavoritesStoriesFromNative,
} from './store/favorites/actions'
import { setTopics } from './store/topics/actions'
import { IS_IOS } from './tools/checkMobile'
import {
  getCookie,
  deleteCookie,
  displayImmediateUpdate,
  isPaywallActive,
  pianoIdAvailable,
} from './tools/tools'
import { messageErrorLoad } from './tools/newversion'
import './system/button/button.css'
import {
  cleanPreference,
  getAllPreferences,
  setAuthors,
  setTags,
  toggleAuthor,
  toggleNotificationAuthor,
  toggleNotificationTag,
  toggleTag,
} from './store/preferences/actions'
import Modal from './system/modal'
import NativeAPI, { sendPostMessage } from './tools/nativeApi'
import { updateCategoriesFromRN } from './store/config/actions'

const ModalUpdate = Loadable({
  loader: () => import('./components/eModal/modalUpdate'),
  loading: () => null,
})

const VerticalHome = Loadable({
  loader: () => import(/* webpackChunkName: "VerticalHome" */ './pages/home'),
  loading: ({ error }) => {
    if (error) messageErrorLoad()
    return <LoadPage />
  },
})

const Home = Loadable({
  loader: () => import(/* webpackChunkName: "Home" */ './pages/portrait'),
  loading: ({ error }) => {
    if (error) messageErrorLoad()
    return <LoadPage />
  },
})

const Notice = Loadable({
  loader: () => import(/* webpackChunkName: "Notice" */ './pages/notice'),
  loading: ({ error }) => {
    if (error) messageErrorLoad()
    return <LoadPage />
  },
})

const Favorites = Loadable({
  loader: () => import(/* webpackChunkName: "Favorites" */ './pages/favorites'),
  loading: ({ error }) => {
    if (error) messageErrorLoad()
    return <LoadPage />
  },
})

const Story = Loadable({
  loader: () => import(/* webpackChunkName: "Story" */ './pages/story'),
  loading: ({ error }) => {
    if (error) messageErrorLoad()
    return <LoadPage />
  },
})

const Blog = Loadable({
  loader: () => import(/* webpackChunkName: "Blog" */ './pages/blog'),
  loading: ({ error }) => {
    if (error) messageErrorLoad()
    return <LoadPage />
  },
})

const NoMatch = Loadable({
  loader: () => import(/* webpackChunkName: "NoMatch" */ './pages/nomatch'),
  loading: () => null,
})

const PAGES = {
  initial: VerticalHome,
  home: Home,
  favorites: Favorites,
  notice: Notice,
  blog: Blog,
}

const CustomRoute = ({ zIndex, component: PageComponent, ...rest }) => (
  <section className="route-section" style={{ zIndex }}>
    <Route {...rest} component={PageComponent} />
  </section>
)

const PAYWALL_ACTIVE = isPaywallActive()
const PIANO_ID_AVAILABLE = pianoIdAvailable()

const getUserProfile = () => {
  try {
    const userCookie = getCookie('eeecappuser')
    if (userCookie) {
      const value = JSON.parse(userCookie)
      if (!value.id) return null
      Sentry.configureScope(scope => {
        scope.setUser({ id: value.id, email: value.email })
      })
      return {
        email: value.email,
        uuid: value.id,
        emailVerified: true,
      }
    }

    const userLocalStorage = localStorage.getItem('ArcId.USER_PROFILE')
    if (!userLocalStorage) return null
    const data = JSON.parse(userLocalStorage)
    if (!data) return null
    deleteCookie('arc_e_id', '/')
    localStorage.removeItem('topicsState')
    localStorage.removeItem('userHaveSubscription')
    sendPostMessage({ type: 'auth.SIGN_OUT' })
    return null
  } catch (err) {
    Sentry.captureException(err)
    return null
  }
}

interface Props {
  config: any
  dispatch: any
  history: any
  location: any
  preferences: any
  favorites: any
}

interface State {
  appState: any
}
class App extends Component<Props, State> {
  update: (dataUpdate: any) => void
  togglePaywallStatus: (status: boolean) => void
  categories: any
  changeCategories: (categories: any) => void
  signOut: () => void
  signIn: (profile: any, fromListener?: boolean) => void
  updateFollow: (entity: string) => (body: any) => void
  updateNotifications: (entity: string) => (body: any) => void
  setNightMode: (mode: boolean) => void

  constructor(props) {
    super(props)
    const { config } = props
    this.categories = config.navigation.topTabNavigator
    /* GET USER PROFILE */
    const dataUser = getUserProfile()

    this.changeCategories = categories => {
      this.setState(state => ({
        appState: { ...state.appState, categories },
      }))
    }

    this.signOut = () => {
      localStorage.removeItem('userHaveSubscription')
      NativeAPI.updateSubscriptionStatus(false)
      this.setState(prevState => ({
        appState: {
          ...prevState.appState,
          profile: null,
          paywallStatus: false,
        },
      }))
      this.props.dispatch(cleanPreference())
      this.props.dispatch(clearFavorites())
      this.props.dispatch(getAllPreferences(false))
    }

    this.signIn = (profile, fromListener) => {
      if (!fromListener) {
        NativeAPI.loadSession(localStorage.getItem('ArcId.USER_INFO'))
      }
      this.setState(prevState => ({
        appState: {
          ...prevState.appState,
          ...profile,
        },
      }))
    }

    this.update = dataUpdate => {
      this.setState(prevState => ({
        appState: {
          ...prevState.appState,
          dataUpdate,
        },
      }))
    }

    this.togglePaywallStatus = status => {
      if (status) {
        localStorage.setItem('userHaveSubscription', 'si')
      } else {
        localStorage.removeItem('userHaveSubscription')
      }
      this.props.dispatch(getAllPreferences(status))
      NativeAPI.updateSubscriptionStatus(status)
      this.setState(prevState => ({
        appState: {
          ...prevState.appState,
          paywallStatus: status,
        },
      }))
    }

    this.updateFollow = entity => body => {
      const { paywallStatus } = this.state.appState
      const { dispatch } = this.props
      switch (entity) {
        case 'authors':
          dispatch(toggleAuthor(body, paywallStatus))
          break
        case 'tags':
          dispatch(toggleTag(body, paywallStatus))
          break
        default:
          break
      }
    }

    this.updateNotifications = entity => async body => {
      const { dispatch } = this.props
      const { paywallStatus } = this.state.appState
      switch (entity) {
        case 'authors':
          await dispatch(toggleNotificationAuthor(body, paywallStatus))
          break
        case 'tags':
          await dispatch(toggleNotificationTag(body, paywallStatus))
          break
        default:
          break
      }
    }

    this.setNightMode = mode => {
      this.setState(prevState => ({
        ...prevState,
        appState: {
          ...prevState.appState,
          nightMode: mode,
        },
      }))
    }

    this.state = {
      appState: {
        categories: this.categories,
        changeCategories: this.changeCategories,
        nightMode: UserConfig.get('mode'),
        signOut: this.signOut,
        signIn: this.signIn,
        update: this.update,
        dataUpdate: null,
        profile: dataUser,
        paywallStatus: Boolean(localStorage.getItem('userHaveSubscription')),
        togglePaywallStatus: this.togglePaywallStatus,
        updateFollow: this.updateFollow,
        updateNotifications: this.updateNotifications,
        setNightMode: this.setNightMode,
      },
    }
  }

  componentDidMount() {
    const {
      config: { name },
      dispatch,
      history,
    } = this.props
    const { appState } = this.state

    this.categories.forEach(category => {
      scrollCategories[category.key] = 0
    })

    dispatch(initStateFavorites())
    localStorage.removeItem('recents')

    /**
     * Quitar del localStorage las categorias personalizas
     *
     * @link
     * https://app.clickup.com/t/1uhk3fn
     */
    localStorage.removeItem('customTabCategories')

    /* SENTRY */
    const prod = /pwa\.(depor|elcomercio|trome|gestion)\.(pe|com)/.test(
      window.location.hostname,
    )
    Sentry.init({
      dsn: 'https://aa2c63eadc6b4402a38b2f796653323f@sentry.ec.pe/15',
      environment: prod ? 'production' : 'development',
      release: `${process.env.REACT_APP_VERSION}.${window.PACKAGE?.version}`,
      debug: !prod,
      whitelistUrls: [
        /pwa.(dev.)?(elcomercio|depor|gestion|trome).(pe|com)\/static\/js/,
      ],
      blacklistUrls: [/s0\.2mdn\.net/, /platform\.twitter\.com/],
      // ignoreErrors: ['ServiceWorker'],
      beforeBreadcrumb(breadcrumb) {
        if (breadcrumb?.data?.url?.includes('stats.g.doubleclick.net')) {
          return null
        }
        return breadcrumb
      },
      beforeSend(event, hint = {}) {
        if (process.env.REACT_APP_ENVIRONMENT === 'development') {
          console.log(event, hint)
        }
        const evt = event
        const error = hint.originalException as Error
        if (error?.message && evt.tags) {
          const match = error.message.match(/^\[(.*?)\]/i)
          if (match) {
            const [, categoryError] = match
            evt.tags.category = categoryError
          }
        }
        return evt
      },
    })
    Sentry.configureScope(scope => {
      scope.setTag('brand', name)
      scope.setTag('category', 'GENERAL')
    })

    if (appState.profile) {
      this.signIn({ profile: appState.profile }, true)
    } else {
      dispatch(getAllPreferences(false))
    }

    const IMMEDIATE_UPDATE = displayImmediateUpdate()
    if (IMMEDIATE_UPDATE) {
      Modal.open({
        content: () => <ModalUpdate />,
        myClass: 'is-modal-center is-modal-swh',
        animation: 'centerFade',
        disableBack: true,
      })
    }

    /* Fix for input on iOS - virtual keyword */
    if (IS_IOS()) {
      window.addEventListener(
        'blur',
        event => {
          const target = event.target as HTMLElement
          if (
            target &&
            (target.tagName === 'INPUT' ||
              target.tagName === 'SELECT' ||
              target.tagName === 'TEXTAREA')
          ) {
            setTimeout(() => {
              window.scrollTo(document.body.scrollLeft, document.body.scrollTop)
            }, 0)
          }
        },
        true,
      )
    }

    /* Postmessage from React Native */
    window.addEventListener(
      'message',
      event => {
        const { content, type, payload } = event.data
        if (type === 'auth.PROFILE' && PIANO_ID_AVAILABLE) {
          const profile = {
            email: payload.email,
            uuid: payload.id,
            emailVerified: true,
          }
          this.setState(prevState => ({
            ...prevState,
            appState: {
              ...prevState.appState,
              profile,
            },
          }))
          const noticeWrapper = document.querySelector('.wrap-view-new')
          if (noticeWrapper) noticeWrapper.removeAttribute('style')
          window.currentModal?.modal?.remove()
          // Remove ArcId
          localStorage.removeItem('ArcId.USER_INFO')
          localStorage.removeItem('ArcId.USER_PROFILE')
          // Hide Relogin Notification
          document.querySelector('.notification-relogin')?.classList.add('out')
          Sentry.configureScope(scope => {
            scope.setUser({ id: payload.id, email: payload.email })
          })
          return
        }
        if (type === 'auth.LOGOUT') {
          this.signOut()
        }
        if (type === 'subscription.UPDATE_STATUS' && PIANO_ID_AVAILABLE) {
          this.togglePaywallStatus(payload.status)
          return
        }
        if (type !== 'pushHistory') return
        try {
          const url = new URL(content)
          history.push({
            pathname: url.pathname,
            search: '?ref=openapp',
          })
        } catch (error) {
          console.error(error, content)
        }
      },
      false,
    )

    window.currentModal = {
      modal: null,
      onClose: user => {
        this.signIn(
          {
            profile: user,
          },
          true,
        )
      },
    }

    const payload: { auth?: Record<string, string> } = {}
    if (appState.profile?.uuid) payload.auth = appState.profile
    sendPostMessage({ type: 'init.WEB_LOADED', payload })
    window.appHistory = this.props.history

    const initialData = {
      isSubscriptor: appState.paywallStatus,
      paywallActive: PAYWALL_ACTIVE,
      pages: [],
    }

    NativeAPI.loadInitialData(initialData)
    if (PAYWALL_ACTIVE && !appState.paywallStatus) {
      NativeAPI.triggerInAppEvent('campaign_non_subscribers')
    }

    window.NATIVE_CONNECTION = {
      favorites: {
        setIds: (ids: string[]) => {
          dispatch(setFavoritesIds(ids))
        },
        setStories: (stories: []) => {
          dispatch(setFavoritesStoriesFromNative(stories))
        },
      },
      preferences: {
        setAuthors: authors => dispatch(setAuthors(authors)),
        setTags: tags => dispatch(setTags(tags)),
      },
      categories: {
        setCategoriesConfig: config => {
          dispatch(updateCategoriesFromRN(config, this.changeCategories))
        },
      },
      mode: { setNightMode: this.setNightMode },
      topics: { setTopics: (topics: string[]) => dispatch(setTopics(topics)) },
    }
  }

  render() {
    const {
      config: {
        main,
        marketing,
        name,
        pages,
      },
      location,
    } = this.props
    const { appState } = this.state
    const myKey = location.pathname.split('/')[1] || 'category'

    const classes = classNames(name, {
      'night-mode': appState.nightMode,
      [`zoom-${UserConfig.get('fontSize')}`]: true,
    })

    const pageCategories = appState.categories.filter(c => c.id !== 'portada')

    return (
      <div>
        <Helmet>
          <meta name="theme-color" content={marketing.firstColor} />
          <link id="link-canonical" rel="canonical" href={marketing.url} />
          <link rel="shortcut icon" href={`/brands/${name}/favicon.png`} />
          <link
            rel="preconnect"
            href={`https://img.${marketing.domain}`}
            crossOrigin="use-credentials"
          />
          <link rel="preload" as="image" href={`/brands/${name}/logo.svg`} />
          <link
            rel="preload"
            as="image"
            href={`/brands/${name}/placeholder.svg`}
          />
          <link rel="dns-prefetch" href="https://adservice.google.com" />
          <link rel="preconnect" href="https://adservice.google.com" />
          <link rel="dns-prefetch" href="https://tpc.googlesyndication.com" />
          <link rel="preconnect" href="https://tpc.googlesyndication.com" />
          <link rel="preconnect" href="http://pagead2.googlesyndication.com" />
          <link
            rel="dns-prefetch"
            href="http://pagead2.googlesyndication.com"
          />

          <link rel="preconnect" href="https://fonts.googleapis.com" />
          {marketing.font && <link href={marketing.font} rel="stylesheet" />}
          {name === 'elcomercio' && (
            <link href={`/brands/elcomercio/styles.css`} rel="stylesheet" />
          )}
          <title>{marketing.brand}</title>
          <style type="text/css">
            {`
            .splash-screen, .is-header.is-primary, .e-head.bg-brand {
              background-color: ${marketing.firstColor};
            }
            .categories-menu-indicator {
              background-color: ${marketing.secondaryColor};
            }
            .page-view {
              height: 100vh;
            }
            @supports (padding: env(safe-area-inset-bottom)) {
              .page-view {
                height: 100vh;
              }
            }
          `}
          </style>
          <body className={classes} />
        </Helmet>
        <AppContext.Provider value={appState}>
          {main === '/' ? (
            <Route path="/category/:category" component={SectionPage} />
          ) : (
            <Route exact path="/" render={() => <Redirect to={main} />} />
          )}
          <TransitionGroup className="transition-group">
            <CSSTransition
              key={myKey}
              timeout={{ enter: 300, exit: 300 }}
              classNames="fade"
            >
              <Switch location={location}>
                {pages.map(page => (
                  <CustomRoute
                    key={page.path}
                    path={page.path}
                    component={PAGES[page.component]}
                    zIndex={
                      page.component === 'notice' || page.component === 'blog'
                        ? 4
                        : 'auto'
                    }
                    exact={page.path === '/'}
                  />
                ))}
                {pageCategories.map(page => (
                  <CustomRoute
                    key={page.path}
                    path={`/${page.key}/:notice`}
                    component={Notice}
                    zIndex={4}
                  />
                ))}
                <CustomRoute
                  path={'/story/:storyId'}
                  component={Story}
                  zIndex={4}
                />
                <Route component={NoMatch} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </AppContext.Provider>
      </div>
    )
  }
}

const AppWrapper: React.FC<any> = props => {
  return (
    <div>
      {props.config.categories ? (
        <ErrorBoundary fallback={<>Error 500</>}>
          <App {...props} />
        </ErrorBoundary>
      ) : (
        ''
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  config: state.configBrand,
})

export default withRouter(connect(mapStateToProps)(AppWrapper))
