import React from 'react'
import ReactDOM from 'react-dom'
import Loadable from 'react-loadable'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import App from './App'
import createStore from './store'
import { initializeConfig } from './store/config/actions'

declare global {
  interface Window {
    NATIVE_CONNECTION: any
    PACKAGE: {
      version: number
    }
    REDUX_DATA: any
    STORY_DATA: any
    payU: any
    currentModal: {
      modal: any
      onClose?: (param?: any) => void
      track?: string
    }
    appHistory: any
  }
}

const store = createStore(window.REDUX_DATA)
store.dispatch(initializeConfig(window.location.hostname))

const jsx = (
  <ReduxProvider store={store}>
    <Router>
      <App />
    </Router>
  </ReduxProvider>
)

Loadable.preloadReady().then(() => {
  const root = document.getElementById('root')
  ReactDOM.render(jsx, root)
})
