import 'intersection-observer'
// import './polyfill/intersection-observer';
import 'core-js/features/array/find'
import 'core-js/features/array/find-index'
import 'core-js/features/array/from'
import 'core-js/features/array/includes'
import 'core-js/features/object/assign'
import './polyfill/object-keys'
import './polyfill/startsWith'
/* import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es7/object'; */
import 'unfetch/polyfill'
import 'promise-polyfill/src/polyfill'
import smoothscroll from 'smoothscroll-polyfill'

smoothscroll.polyfill()
